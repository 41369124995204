import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Play = () => {
  let {markets} = useSelector(state => state?.markets?.markets);

  
  return (
    <div className="p-3 pb-8">
      <div className="flex flex-col gap-1">
        {markets?.map((market) => (
          <div key={market.name} className="flex p-3 text-white rounded-md bg-primary">
            <span className="text-sm font-semibold uppercase">{market?.name}</span>
            <Link
              className={`px-2 font-semibold ml-auto py-0.5 text-xs rounded-md shadow-md ${
                !market?.game_on ? "bg-orange" : "bg-greenLight"
              }`}
              to={!market?.game_on ? "#" : `/play-game?gameType=${market.name}&market_id=${market?.id}`}
            >
              {!market?.game_on ? "Time Out" : "Play Games"}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Play;
